<template>
  <div app :dark="$vuetify.dark">
    <v-container id="editWindow">
      <v-row dense align-content="stretch">
        <v-col>
          <v-card class="mx-auto" max-width="400">
            <v-toolbar flat>
              <v-tooltip :disabled="isShowTooltip" bottom color="white">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" v-on="on" icon @click="expandFilters">
                    <v-icon>mdi-filter-menu-outline</v-icon>
                  </v-btn>
                </template>
                <span style="color: black">Filter</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-toolbar-title>Read Only Edits</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip :disabled="isShowTooltip" bottom color="white">
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    v-on="on"
                    icon
                    @click="refreshEditsList"
                    :disabled="!areFiltersValid"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span style="color: black">Refresh</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text v-if="isFilterMenu">
              <edit-settings />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-card>
            <v-card-text
              :class="`${isEditsListVisible ? 'no-padding' : 'nonZeroPadding'}`"
            >
              <v-progress-linear
                indeterminate
                rounded
                height="3"
                v-if="!editNameList && isEditsListVisible"
              />
              <span>
                <edit-list v-show="isEditsListVisible" />
              </span>
              <span v-if="!isEditsListVisible && !editName">
                <p v-if="editNameList" align="center">No edit selected</p>
                <p v-else align="center">No edits in this region</p>
              </span>
              <span style="height:10px">
                <edit-list v-show="!isEditsListVisible && editName" />
              </span>
            </v-card-text>
            <v-card-actions style="height:2px">
              <v-spacer></v-spacer>
              <v-tooltip :disabled="isShowTooltip" bottom color="white">
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    v-on="on"
                    icon
                    @click="changeListVisibility"
                    :disabled="!editNameList"
                  >
                    <v-icon>{{
                      isEditsListVisible ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </template>
                <span style="color: black">{{
                  isEditsListVisible ? "hide" : "show"
                }}</span>
              </v-tooltip>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "edit",
  components: {
    EditSettings: () => import("@/components/EditSettings"),
    EditList: () => import("@/components/EditList")
  },
  computed: {
    ...mapState({
      editName: state => state.edit.currentSelectedEdit.name,
      isFilterMenu: state => state.edit.filterMenu,
      isEditsListVisible: state => state.edit.isEditsListVisible,
      editNameList: state => state.edit.editNameList.length > 0,
      isFiltersSaveEnabled: state => state.edit.isFiltersSaveEnabled,
      editsFilterOptions: state => state.edit.editsFilterOptions,
      areFiltersValid: state => state.edit.areFiltersValid,
      isShowTooltip: state => !state.setting.isToolTipsOn
    })
  },
  methods: {
    expandFilters() {
      this.$store.commit("edit/changeFilterMenu", true);
    },
    refreshEditsList() {
      this.$store
        .dispatch("edit/handleFetchEditsInBoundingBox", true)
        .then(() => {
          this.$store.commit("edit/changeFilterMenu", false);
        });
    },
    changeListVisibility() {
      this.$store.commit(
        "edit/changeIsEditsListVisible",
        !this.isEditsListVisible
      );
    }
  }
};
</script>
<style>
#editWindow {
  z-index: 10;
  left: 30px;
  top: 55px;
  position: absolute;
  width: auto;
  min-width: 400px;
  max-height: 600px;
}
.no-info-padding {
  padding-left: 0px;
}
.nonZeroPadding {
  padding: 10px 0px 0px 0px;
}
</style>
